/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@with-nx/auth";
import { Toast } from "@with-nx/hooks-n-helpers";
import { useEffect, useState } from "react";

import useService from "./useService";

export type DropCollection = {
  id: string | number;
  name: string;
  active: boolean;
  createdAt?: string;
  updatedAt?: string;
  dropIds: number[];
  userDropIds: number[];
};

export type DropCollectionDrop = {
  id: string;
  name: string;
  previewVideo: string;
  previewStill: string;
  thumbnail: string;
};

export type DropCollectionUserDrop = {
  id: string;
  name: string;
  previewVideo: string;
  previewStill: string;
  thumbnail: string;
};

export async function createDropCollection(token: string, name: string) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });
  const response = await makeRequestToBackstage(
    "POST",
    "/api/v2/stageplayer/drop-collections",
    {
      data: {
        type: "drop-collections",
        attributes: {
          name: name,
        },
      },
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function getDropCollections(token: string, cache: boolean = true) {
  const makeRequestToBackstage = useService(
    "accounts",
    cache
      ? {
          cache: 30,
        }
      : {}
  );
  const response = await makeRequestToBackstage(
    "GET",
    "/api/v2/stageplayer/drop-collections",
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  return response;
}

export async function getDropCollection(token: string, id: string | number) {
  const makeRequestToBackstage = useService("accounts");
  const response = await makeRequestToBackstage(
    "GET",
    `/api/v2/stageplayer/drop-collections/${id}`,
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  return response;
}

export async function getDropCollectionDrops(
  token: string,
  id: string | number
) {
  const makeRequestToBackstage = useService("accounts");

  const response = await makeRequestToBackstage(
    "GET",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/drops`,
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  return response;
}

export async function getDropCollectionUserDrops(
  token: string,
  id: string | number
) {
  const makeRequestToBackstage = useService("accounts");
  const response = await makeRequestToBackstage(
    "GET",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/user-drops`,
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  return response;
}

export async function deleteDropCollection(token: string, id: string) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "DELETE",
    `/api/v2/stageplayer/drop-collections/${id}`,
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function editDropCollection(
  token: string,
  id: string,
  name: string
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "PATCH",
    `/api/v2/stageplayer/drop-collections/${id}`,
    {
      data: {
        type: "drop-collections",
        id: id,
        attributes: {
          name: name,
        },
      },
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function addDropsToDropCollection(
  token: string,
  id: number | string,
  ids: number[]
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "POST",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/drops`,
    {
      data: ids.map((id) => ({ type: "drops", id })),
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function removeDropsInDropCollection(
  token: string,
  id: number | string,
  ids: number[]
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "DELETE",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/drops`,
    {
      data: ids.map((id) => ({ type: "drops", id })),
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function replaceDropsInDropCollection(
  token: string,
  id: number | string,
  ids: number[]
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "PATCH",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/drops`,
    {
      data: ids.map((id) => ({ type: "drops", id })),
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function addUserDropsToDropCollection(
  token: string,
  id: number | string,
  ids: number[]
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "POST",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/user-drops`,
    {
      data: ids.map((id) => ({ type: "user-drops", id })),
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function removeUserDropsInDropCollection(
  token: string,
  id: number | string,
  drops: number[]
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "DELETE",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/user-drops`,
    {
      data: drops.map((id) => ({ type: "user-drops", id: id })),
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function replaceUserDropsInDropCollection(
  token: string,
  id: number | string,
  ids: number[]
) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "PATCH",
    `/api/v2/stageplayer/drop-collections/${id}/relationships/user-drops`,
    {
      data: ids.map((id) => ({ type: "user-drops", id })),
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function activateDropCollection(token: string, id: string) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "PUT",
    `/api/v2/stageplayer/drop-collections/${id}/activate`,
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function disableDropCollection(token: string, id: string) {
  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "PUT",
    `/api/v2/stageplayer/drop-collections/${id}/deactivate`,
    {},
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export async function transformDropCollectionResourceData(
  token: string,
  id: string | number,
  data: any
): Promise<DropCollection> {
  const dropIds = await getDropCollectionDrops(token, id);
  const userDropIds = await getDropCollectionUserDrops(token, id);

  const _dropIds: number[] = [];
  const _userDropIds: number[] = [];

  dropIds?.["data"]?.map((item: any) => {
    _dropIds.push(parseInt(item.id));
  });

  userDropIds?.["data"]?.map((item: any) => {
    _userDropIds.push(parseInt(item.id));
  });

  return {
    id: data.id,
    name: data["attributes"]?.name,
    createdAt: data["attributes"]["created-at"],
    updatedAt: data["attributes"]["updated-at"],
    active: data["attributes"]?.active,
    dropIds: _dropIds,
    userDropIds: _userDropIds,
  };
}

export async function transformDropCollectionsResourceData(
  token: string,
  data: any
): Promise<DropCollection[]> {
  const collections: DropCollection[] = [];

  await Promise.all(
    data?.data?.map(async (item: any) => {
      const transform = await transformDropCollectionResourceData(
        token,
        item.id,
        item
      );

      collections.push(transform);
    })
  );

  return collections;
}

export function transformDropResourceData(data: any): DropCollectionDrop {
  return {
    id: data.id,
    name: data["attributes"]?.name,
    previewStill: data["attributes"]?.["preview-still"],
    previewVideo: data["attributes"]?.["preview-video"],
    thumbnail: data["attributes"]?.thumbnail,
  };
}

export const useCreateDropCollection = () => {
  const [error, _error] = useState(false);
  const [created, _created] = useState(false);

  const create = async (token: string, name: string) => {
    try {
      await createDropCollection(token, name);
      _created(true);
    } catch (e) {
      _error(true);
    }
  };

  return {
    create,
    error,
    created,
  };
};

export async function getSingleDrop(id: string | number) {
  const makeRequestToBackstage = useService("accounts", {
    cache: 1_800_000,
  });

  const request = await makeRequestToBackstage(
    "GET",
    `/api/v2/drops/${id}`,
    {}
  );

  return transformDropResourceData(request?.["data"]);
}

export function transformUserDropResourceData(
  data: any
): DropCollectionUserDrop {
  return {
    id: data.id,
    name: data["attributes"]?.name,
    previewStill: data["links"]?.image,
    previewVideo: data["links"]?.video,
    thumbnail: data["links"]?.thumbnail,
  };
}

export async function getSingleUserDrop(token: string, id: string | number) {
  const makeRequestToBackstage = useService("accounts", {
    cache: 1_800_000,
  });

  const request = await makeRequestToBackstage(
    "GET",
    `/api/v2/stageplayer/user-drops/${id}`,
    {},
    {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${token}`,
    }
  );

  return transformUserDropResourceData(request?.["data"]);
}

export async function deleteUserDrop(token: string, id: string | number) {
  const makeRequestToBackstage = useService("accounts");

  const request = await makeRequestToBackstage(
    "DELETE",
    `/api/v2/stageplayer/user-drops/${id}`,
    {},
    {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${token}`,
    }
  );

  return request;
}

export async function getUserDrops(token: string) {
  const makeRequestToBackstage = useService("accounts");

  const request = await makeRequestToBackstage(
    "GET",
    `/api/v2/stageplayer/user-drops`,
    {},
    {
      "Content-Type": "application/vnd.api+json",
      Authorization: `Bearer ${token}`,
    }
  );

  const data = request?.["data"]?.map((i: any) =>
    transformUserDropResourceData(i)
  ) as DropCollectionUserDrop[];

  return data;
}

export async function addCustomerUpload(
  token: string,
  filename: string,
  type: string,
  id: string
) {
  const attributes: {
    [key: string]: unknown;
  } = {
    name: filename,
  };

  if (type === "image") {
    attributes["image"] = id;
  } else {
    attributes["video"] = id;
  }

  const makeRequestToBackstage = useService("accounts", {
    bypass: true,
  });

  const response = await makeRequestToBackstage(
    "POST",
    `/api/v2/stageplayer/user-drops`,
    {
      data: {
        type: "user-drops",
        attributes,
      },
    },
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/vnd.api+json",
    }
  );

  if (response?.["errors"]?.[0]) {
    Toast.error(response?.["errors"]?.[0]);
  }

  return response;
}

export const useDropCollection = (id: string) => {
  const auth = useAuth();

  const [data, _data] = useState<DropCollection | null>(null);
  const [error, _error] = useState(false);
  const [loading, _loading] = useState(true);

  const token = auth?.authState?.session?.token as string;

  const fetch = async () => {
    try {
      const response = await getDropCollection(token, id);
      const transform = await transformDropCollectionResourceData(
        token,
        id,
        response?.["data"]
      );

      _data(transform);
    } catch (e) {
      console.error(e);
    } finally {
      _loading(false);
    }
  };

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }

    fetch();
  }, [id, auth.authState.status]);

  const helpers = {
    drop: {
      add: async (ids: number[]) => {
        try {
          const temporary = structuredClone(data) as DropCollection;
          temporary.dropIds = [...(temporary?.dropIds || []), ...ids];
          _data(temporary);

          await addDropsToDropCollection(
            auth?.authState?.session?.token as string,
            id,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      remove: async (ids: number[]) => {
        try {
          const temporary = structuredClone(data) as DropCollection;
          temporary.dropIds = temporary?.dropIds?.filter(
            (i) => !ids.includes(i)
          );
          _data(temporary);

          await removeDropsInDropCollection(
            auth?.authState?.session?.token as string,
            id,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      replace: async (ids: number[]) => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.dropIds = ids;
        _data(temporary);

        try {
          await replaceDropsInDropCollection(
            auth?.authState?.session?.token as string,
            id,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
    },
    user: {
      add: async (ids: number[]) => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.userDropIds = [
          ...(temporary?.userDropIds || []),
          ...ids,
        ] as number[];
        _data(temporary);

        try {
          await addUserDropsToDropCollection(
            auth?.authState?.session?.token as string,
            id,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      remove: async (ids: number[]) => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.userDropIds = temporary?.userDropIds?.filter(
          (i) => !ids.includes(i)
        );
        _data(temporary);

        try {
          await removeUserDropsInDropCollection(
            auth?.authState?.session?.token as string,
            id,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      replace: async (ids: number[]) => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.userDropIds = ids;
        _data(temporary);

        try {
          await replaceUserDropsInDropCollection(
            auth?.authState?.session?.token as string,
            id,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
    },
    collection: {
      remove: async () => {
        try {
          await deleteDropCollection(
            auth?.authState?.session?.token as string,
            id
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      edit: async (name: string) => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.name = name;
        _data(temporary);

        try {
          await editDropCollection(
            auth?.authState?.session?.token as string,
            id,
            name
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      activate: async () => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.active = true;
        _data(temporary);

        try {
          await activateDropCollection(
            auth?.authState?.session?.token as string,
            id
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
      disable: async () => {
        const temporary = structuredClone(data) as DropCollection;
        temporary.active = false;
        _data(temporary);

        try {
          await disableDropCollection(
            auth?.authState?.session?.token as string,
            id
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
    },
  };

  return {
    data,
    error,
    loading,
    helpers,
  };
};

export const useDrop = (id: string) => {
  const [data, _data] = useState<DropCollectionDrop | null>(null);
  const [error, _error] = useState(false);
  const [loading, _loading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getSingleDrop(id);
        _data(response);
      } catch (e) {
        _error(true);
      } finally {
        _loading(false);
      }
    };

    fetch();
  }, [id]);

  return {
    data,
    error,
    loading,
  };
};

export const useUserDrop = (id: string) => {
  const [data, _data] = useState<DropCollectionUserDrop | null>(null);
  const [error, _error] = useState(false);
  const [loading, _loading] = useState(true);
  const auth = useAuth();
  const token = auth?.authState?.session?.token as string;

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getSingleUserDrop(token, id);
        _data(response);
      } catch (e) {
        _error(true);
      } finally {
        _loading(false);
      }
    };

    fetch();
  }, [id]);

  return {
    data,
    error,
    loading,
  };
};

export const useDropCollections = () => {
  const auth = useAuth();

  const [data, _data] = useState<DropCollection[] | null>(null);
  const [error, _error] = useState(false);
  const [loading, _loading] = useState(true);

  const token = auth?.authState?.session?.token as string;

  const fetch = async (callback?: () => unknown, cache?: boolean) => {
    try {
      const response = await getDropCollections(token, cache);
      const transform = await transformDropCollectionsResourceData(
        token,
        response
      );

      _data(transform);
      callback?.();
    } catch (e) {
      _error(true);
      callback?.();
    } finally {
      _loading(false);
      callback?.();
    }
  };

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }

    fetch();
  }, [auth.authState.status]);

  const helpers = {
    create: async (name: string, callback?: () => unknown) => {
      const temporary = structuredClone(data) as DropCollection[];
      temporary.push({
        id: temporary.length + 1,
        name,
        active: false,
        userDropIds: [],
        dropIds: [],
      });

      try {
        await createDropCollection(token, name);
      } catch (e) {
        _error(true);
        callback?.();
      } finally {
        fetch(callback, false);
      }
    },
    upload: async (filename: string, type: "image" | "video", id: string) => {
      try {
        await addCustomerUpload(token, filename, type, id);
      } catch (e) {
        _error(true);
      } finally {
        fetch();
      }
    },
    add: async (collection: string, id: string | number) => {
      (() => {
        const temporary = structuredClone(data) as DropCollection[];
        const c = temporary.findIndex(
          (collection) => collection.id === parseInt(String(collection))
        );

        if (c === -1) {
          return;
        }

        temporary[c].userDropIds.push(parseInt(String(id)));
        _data(temporary);
      })();

      try {
        await addDropsToDropCollection(token, collection, [
          parseInt(String(id)),
        ]);
      } catch (e) {
        _error(true);
      } finally {
        fetch();
      }
    },
    user: {
      add: async (collection: string, ids: number[]) => {
        (() => {
          const temporary = structuredClone(data) as DropCollection[];
          const c = temporary.findIndex(
            (collection) => collection.id === parseInt(String(collection))
          );

          if (c === -1) {
            return;
          }

          temporary[c].userDropIds.push(...ids);

          _data(temporary);
        })();

        try {
          await addUserDropsToDropCollection(
            auth?.authState?.session?.token as string,
            collection,
            ids
          );
        } catch (e) {
          _error(true);
        } finally {
          fetch();
        }
      },
    },
  };

  return {
    data,
    error,
    loading,
    helpers,
  };
};

export const useCustomerUploads = () => {
  const auth = useAuth();

  const [data, _data] = useState<DropCollectionUserDrop[] | null>(null);
  const [error, _error] = useState(false);
  const [loading, _loading] = useState(true);

  const token = auth?.authState?.session?.token as string;

  const fetch = async () => {
    try {
      const response = await getUserDrops(token);

      _data(response);
    } catch (e) {
      _error(true);
    } finally {
      _loading(false);
    }
  };

  useEffect(() => {
    if (auth.authState.status !== "authenticated") {
      return;
    }
    fetch();
  }, [auth.authState.status]);

  const helpers = {
    remove: async (id: number) => {
      try {
        await deleteUserDrop(token, id);
      } catch (e) {
        _error(true);
      } finally {
        fetch();
      }
    },
    add: async (collection: string, ids: number[]) => {
      try {
        await addUserDropsToDropCollection(
          auth?.authState?.session?.token as string,
          collection,
          ids
        );
      } catch (e) {
        _error(true);
      } finally {
        fetch();
      }
    },
  };

  return {
    data,
    error,
    loading,
    helpers,
    reload: fetch,
  };
};
